.Member-image {
    width: 100%;
    margin: 20px 0;
}
.Member-title {
    display: block;

    font-size: 14px;
    line-height: (20/14);
}
.Member-info {
    margin-top: 15px;

    font-size: 13px;
    line-height: (20/13);
}