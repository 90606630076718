.ServiceList {
    @include flex-grid-row('nest');

    margin-bottom: 0;
    justify-content: center;

    list-style: none;
}
.ServiceList-item {
    @include flex-grid-column(8, 40);

    margin: 25px 0 0 ($global-width / $grid-column-count / 2);

    @include breakpoint(medium) {
        @include flex-grid-column(7, 20);

        margin-left: $global-width / $grid-column-count;
    }
    @include breakpoint(large) {
        @include flex-grid-column(3.3, 20);
    }
    @include breakpoint(xlarge) {
        @include flex-grid-column(2, 20);
    }
}
.ServiceList-item--designing {
    @include breakpoint(xlarge) {
        @include flex-grid-column(3, 20);
    }
}
.ServiceList-title {
    margin-bottom: 15px;

    position: relative;

    &:before {
        width: 40px;
        height: 40px;

        position: absolute;
        top: -8px;
        left: auto;
        right: calc(100% + 20px);

        background: no-repeat center center;
        background-size: contain;
        content: "";
    }

    .ServiceList-item--designing & {
        &:before {
            background-image: url(../images/icon-tools.png);
        }
    }
    .ServiceList-item--production & {
        &:before {
            background-image: url(../images/icon-plan.png);
        }
    }
    .ServiceList-item--supervision & {
        &:before {
            background-image: url(../images/icon-worker.png);
        }
    }

    @include breakpoint(xlarge) {
        &:before {
            top: 0;
        }

        .ServiceList-item--designing & {
            &:before {
                width: 70px;
                height: 71px;
            }
        }
        .ServiceList-item--production & {
            &:before {
                width: 71px;
                height: 72px;
            }
        }
        .ServiceList-item--supervision & {
            &:before {
                width: 59px;
                height: 72px;
            }
        }
    }
}
.ServiceList-subList {
    margin-left: 0;
}
.ServiceList-subItem {
    &:before {
        left: -30px;
    }
}
