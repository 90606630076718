.References-image {
    margin: 10px auto;
}

.ReferenceList {
    @include flex-grid-row();

    list-style: none;
}
.ReferenceList-item {
    @include flex-grid-column(10, 40);

    margin: 25px 0;

    @include breakpoint(medium) {
        @include flex-grid-column(5, 20);
    }
}
.ReferenceList-subList {
    margin-left: 30px;
}
.ReferenceList-title,
.ReferenceList-address {
    padding: 0 30px;
}
.ReferenceList-address {
    font-size: 14px;
}
.ReferenceList-subItem {
    font-size: 14px;
    line-height: (26/14);
}
