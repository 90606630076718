@charset "UTF-8";
/* ====================================
   Globals
   ==================================== */
/*
    Foundation
    ==================================== */
/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*
    Foundation components
    ==================================== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1, .h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=37.5em&large=56.25em&xlarge=75em&xxlarge=83.75em&mega=93.75em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fff;
  font-family: "Merriweather Sans", "Roboto", Arial, sans-serif;
  font-weight: normal;
  line-height: 1.625;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, .h1, .h1,
h2,
.h2, .h2,
h3,
.h3, .h3,
h4,
.h4, .h4,
h5,
.h5, .h5,
h6,
.h6, .h6 {
  font-family: "Roboto", Arial, sans-serif;
  font-style: normal;
  font-weight: 900;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small, .h1 small, .h1 small,
  h2 small, .h2 small, .h2 small,
  h3 small, .h3 small, .h3 small,
  h4 small, .h4 small, .h4 small,
  h5 small, .h5 small, .h5 small,
  h6 small, .h6 small, .h6 small {
    line-height: 0;
    color: #ccc; }

h1, .h1, .h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2, .h2, .h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3, .h3, .h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4, .h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5, .h5, .h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6, .h6, .h6 {
  font-size: 0.875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 37.5em) {
  h1, .h1, .h1 {
    font-size: 2.25rem; }
  h2, .h2, .h2 {
    font-size: 1.625rem; }
  h3, .h3, .h3 {
    font-size: 1.5rem; }
  h4, .h4, .h4 {
    font-size: 1.25rem; }
  h5, .h5, .h5 {
    font-size: 1.0625rem; }
  h6, .h6, .h6 {
    font-size: 0.875rem; } }

@media print, screen and (min-width: 56.25em) {
  h1, .h1, .h1 {
    font-size: 3rem; }
  h2, .h2, .h2 {
    font-size: 2.625rem; }
  h3, .h3, .h3 {
    font-size: 1.875rem; }
  h4, .h4, .h4 {
    font-size: 1.5rem; }
  h5, .h5, .h5 {
    font-size: 1.375rem; }
  h6, .h6, .h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #344ba0;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #2d418a; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #ccc;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #ccc; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #666; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #666; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #ccc;
  background-color: #e8e8e8;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #000; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e8e8e8;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #000; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #666; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 37.5em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 56.25em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

@media screen and (min-width: 75em) {
  .xlarge-text-left {
    text-align: left; }
  .xlarge-text-right {
    text-align: right; }
  .xlarge-text-center {
    text-align: center; }
  .xlarge-text-justify {
    text-align: justify; } }

@media screen and (min-width: 83.75em) {
  .xxlarge-text-left {
    text-align: left; }
  .xxlarge-text-right {
    text-align: right; }
  .xxlarge-text-center {
    text-align: center; }
  .xxlarge-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #666;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  .h2,
  h3,
  .h3 {
    orphans: 3;
    widows: 3; }
  h2, .h2,
  h3,
  .h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 37.4375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 37.5em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 37.5em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 37.4375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 37.5em) and (max-width: 56.1875em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 37.4375em), screen and (min-width: 56.25em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 56.25em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 56.1875em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 56.25em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 56.1875em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

@media screen and (min-width: 75em) {
  .hide-for-xlarge {
    display: none !important; } }

@media screen and (max-width: 74.9375em) {
  .show-for-xlarge {
    display: none !important; } }

@media screen and (min-width: 75em) and (max-width: 83.6875em) {
  .hide-for-xlarge-only {
    display: none !important; } }

@media screen and (max-width: 74.9375em), screen and (min-width: 83.75em) {
  .show-for-xlarge-only {
    display: none !important; } }

@media screen and (min-width: 83.75em) {
  .hide-for-xxlarge {
    display: none !important; } }

@media screen and (max-width: 83.6875em) {
  .show-for-xxlarge {
    display: none !important; } }

@media screen and (min-width: 83.75em) and (max-width: 93.6875em) {
  .hide-for-xxlarge-only {
    display: none !important; } }

@media screen and (max-width: 83.6875em), screen and (min-width: 93.75em) {
  .show-for-xxlarge-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1; }

.clearfix::after {
  clear: both; }

.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap; }
  .row .row {
    margin-right: -1.25rem;
    margin-left: -1.25rem; }
    @media print, screen and (min-width: 37.5em) {
      .row .row {
        margin-right: -0.625rem;
        margin-left: -0.625rem; } }
    @media print, screen and (min-width: 56.25em) {
      .row .row {
        margin-right: -0.625rem;
        margin-left: -0.625rem; } }
    @media screen and (min-width: 75em) {
      .row .row {
        margin-right: -0.625rem;
        margin-left: -0.625rem; } }
    @media screen and (min-width: 83.75em) {
      .row .row {
        margin-right: -0.625rem;
        margin-left: -0.625rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row.is-collapse-child,
  .row.collapse > .column > .row,
  .row.collapse > .columns > .row {
    margin-right: 0;
    margin-left: 0; }

.column, .columns {
  flex: 1 1 0px;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  min-width: 0; }
  @media print, screen and (min-width: 37.5em) {
    .column, .columns {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }

.column.row.row, .row.row.columns {
  float: none;
  display: block; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  flex: 0 0 10%;
  max-width: 10%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  flex: 0 0 20%;
  max-width: 20%; }

.small-offset-1 {
  margin-left: 10%; }

.small-3 {
  flex: 0 0 30%;
  max-width: 30%; }

.small-offset-2 {
  margin-left: 20%; }

.small-4 {
  flex: 0 0 40%;
  max-width: 40%; }

.small-offset-3 {
  margin-left: 30%; }

.small-5 {
  flex: 0 0 50%;
  max-width: 50%; }

.small-offset-4 {
  margin-left: 40%; }

.small-6 {
  flex: 0 0 60%;
  max-width: 60%; }

.small-offset-5 {
  margin-left: 50%; }

.small-7 {
  flex: 0 0 70%;
  max-width: 70%; }

.small-offset-6 {
  margin-left: 60%; }

.small-8 {
  flex: 0 0 80%;
  max-width: 80%; }

.small-offset-7 {
  margin-left: 70%; }

.small-9 {
  flex: 0 0 90%;
  max-width: 90%; }

.small-offset-8 {
  margin-left: 80%; }

.small-10 {
  flex: 0 0 100%;
  max-width: 100%; }

.small-offset-9 {
  margin-left: 90%; }

.small-up-1 {
  flex-wrap: wrap; }
  .small-up-1 > .column, .small-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%; }

.small-up-2 {
  flex-wrap: wrap; }
  .small-up-2 > .column, .small-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%; }

.small-up-3 {
  flex-wrap: wrap; }
  .small-up-3 > .column, .small-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }

.small-up-4 {
  flex-wrap: wrap; }
  .small-up-4 > .column, .small-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%; }

.small-up-5 {
  flex-wrap: wrap; }
  .small-up-5 > .column, .small-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%; }

.small-up-6 {
  flex-wrap: wrap; }
  .small-up-6 > .column, .small-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }

.small-up-7 {
  flex-wrap: wrap; }
  .small-up-7 > .column, .small-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }

.small-up-8 {
  flex-wrap: wrap; }
  .small-up-8 > .column, .small-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 1.25rem;
  padding-left: 1.25rem; }

@media print, screen and (min-width: 37.5em) {
  .medium-1 {
    flex: 0 0 10%;
    max-width: 10%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    flex: 0 0 20%;
    max-width: 20%; }
  .medium-offset-1 {
    margin-left: 10%; }
  .medium-3 {
    flex: 0 0 30%;
    max-width: 30%; }
  .medium-offset-2 {
    margin-left: 20%; }
  .medium-4 {
    flex: 0 0 40%;
    max-width: 40%; }
  .medium-offset-3 {
    margin-left: 30%; }
  .medium-5 {
    flex: 0 0 50%;
    max-width: 50%; }
  .medium-offset-4 {
    margin-left: 40%; }
  .medium-6 {
    flex: 0 0 60%;
    max-width: 60%; }
  .medium-offset-5 {
    margin-left: 50%; }
  .medium-7 {
    flex: 0 0 70%;
    max-width: 70%; }
  .medium-offset-6 {
    margin-left: 60%; }
  .medium-8 {
    flex: 0 0 80%;
    max-width: 80%; }
  .medium-offset-7 {
    margin-left: 70%; }
  .medium-9 {
    flex: 0 0 90%;
    max-width: 90%; }
  .medium-offset-8 {
    margin-left: 80%; }
  .medium-10 {
    flex: 0 0 100%;
    max-width: 100%; }
  .medium-offset-9 {
    margin-left: 90%; }
  .medium-up-1 {
    flex-wrap: wrap; }
    .medium-up-1 > .column, .medium-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .medium-up-2 {
    flex-wrap: wrap; }
    .medium-up-2 > .column, .medium-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .medium-up-3 {
    flex-wrap: wrap; }
    .medium-up-3 > .column, .medium-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .medium-up-4 {
    flex-wrap: wrap; }
    .medium-up-4 > .column, .medium-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .medium-up-5 {
    flex-wrap: wrap; }
    .medium-up-5 > .column, .medium-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .medium-up-6 {
    flex-wrap: wrap; }
    .medium-up-6 > .column, .medium-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .medium-up-7 {
    flex-wrap: wrap; }
    .medium-up-7 > .column, .medium-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .medium-up-8 {
    flex-wrap: wrap; }
    .medium-up-8 > .column, .medium-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 37.5em) and (min-width: 37.5em) {
  .medium-expand {
    flex: 1 1 0px; } }

.row.medium-unstack > .column, .row.medium-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 37.5em) {
    .row.medium-unstack > .column, .row.medium-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 37.5em) {
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; } }

@media print, screen and (min-width: 56.25em) {
  .large-1 {
    flex: 0 0 10%;
    max-width: 10%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    flex: 0 0 20%;
    max-width: 20%; }
  .large-offset-1 {
    margin-left: 10%; }
  .large-3 {
    flex: 0 0 30%;
    max-width: 30%; }
  .large-offset-2 {
    margin-left: 20%; }
  .large-4 {
    flex: 0 0 40%;
    max-width: 40%; }
  .large-offset-3 {
    margin-left: 30%; }
  .large-5 {
    flex: 0 0 50%;
    max-width: 50%; }
  .large-offset-4 {
    margin-left: 40%; }
  .large-6 {
    flex: 0 0 60%;
    max-width: 60%; }
  .large-offset-5 {
    margin-left: 50%; }
  .large-7 {
    flex: 0 0 70%;
    max-width: 70%; }
  .large-offset-6 {
    margin-left: 60%; }
  .large-8 {
    flex: 0 0 80%;
    max-width: 80%; }
  .large-offset-7 {
    margin-left: 70%; }
  .large-9 {
    flex: 0 0 90%;
    max-width: 90%; }
  .large-offset-8 {
    margin-left: 80%; }
  .large-10 {
    flex: 0 0 100%;
    max-width: 100%; }
  .large-offset-9 {
    margin-left: 90%; }
  .large-up-1 {
    flex-wrap: wrap; }
    .large-up-1 > .column, .large-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .large-up-2 {
    flex-wrap: wrap; }
    .large-up-2 > .column, .large-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .large-up-3 {
    flex-wrap: wrap; }
    .large-up-3 > .column, .large-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .large-up-4 {
    flex-wrap: wrap; }
    .large-up-4 > .column, .large-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .large-up-5 {
    flex-wrap: wrap; }
    .large-up-5 > .column, .large-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .large-up-6 {
    flex-wrap: wrap; }
    .large-up-6 > .column, .large-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .large-up-7 {
    flex-wrap: wrap; }
    .large-up-7 > .column, .large-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .large-up-8 {
    flex-wrap: wrap; }
    .large-up-8 > .column, .large-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 56.25em) and (min-width: 56.25em) {
  .large-expand {
    flex: 1 1 0px; } }

.row.large-unstack > .column, .row.large-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 56.25em) {
    .row.large-unstack > .column, .row.large-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 56.25em) {
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; } }

@media screen and (min-width: 75em) {
  .xlarge-1 {
    flex: 0 0 10%;
    max-width: 10%; }
  .xlarge-offset-0 {
    margin-left: 0%; }
  .xlarge-2 {
    flex: 0 0 20%;
    max-width: 20%; }
  .xlarge-offset-1 {
    margin-left: 10%; }
  .xlarge-3 {
    flex: 0 0 30%;
    max-width: 30%; }
  .xlarge-offset-2 {
    margin-left: 20%; }
  .xlarge-4 {
    flex: 0 0 40%;
    max-width: 40%; }
  .xlarge-offset-3 {
    margin-left: 30%; }
  .xlarge-5 {
    flex: 0 0 50%;
    max-width: 50%; }
  .xlarge-offset-4 {
    margin-left: 40%; }
  .xlarge-6 {
    flex: 0 0 60%;
    max-width: 60%; }
  .xlarge-offset-5 {
    margin-left: 50%; }
  .xlarge-7 {
    flex: 0 0 70%;
    max-width: 70%; }
  .xlarge-offset-6 {
    margin-left: 60%; }
  .xlarge-8 {
    flex: 0 0 80%;
    max-width: 80%; }
  .xlarge-offset-7 {
    margin-left: 70%; }
  .xlarge-9 {
    flex: 0 0 90%;
    max-width: 90%; }
  .xlarge-offset-8 {
    margin-left: 80%; }
  .xlarge-10 {
    flex: 0 0 100%;
    max-width: 100%; }
  .xlarge-offset-9 {
    margin-left: 90%; }
  .xlarge-up-1 {
    flex-wrap: wrap; }
    .xlarge-up-1 > .column, .xlarge-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .xlarge-up-2 {
    flex-wrap: wrap; }
    .xlarge-up-2 > .column, .xlarge-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .xlarge-up-3 {
    flex-wrap: wrap; }
    .xlarge-up-3 > .column, .xlarge-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .xlarge-up-4 {
    flex-wrap: wrap; }
    .xlarge-up-4 > .column, .xlarge-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .xlarge-up-5 {
    flex-wrap: wrap; }
    .xlarge-up-5 > .column, .xlarge-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .xlarge-up-6 {
    flex-wrap: wrap; }
    .xlarge-up-6 > .column, .xlarge-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .xlarge-up-7 {
    flex-wrap: wrap; }
    .xlarge-up-7 > .column, .xlarge-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .xlarge-up-8 {
    flex-wrap: wrap; }
    .xlarge-up-8 > .column, .xlarge-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media screen and (min-width: 75em) and (min-width: 75em) {
  .xlarge-expand {
    flex: 1 1 0px; } }

.row.xlarge-unstack > .column, .row.xlarge-unstack > .columns {
  flex: 0 0 100%; }
  @media screen and (min-width: 75em) {
    .row.xlarge-unstack > .column, .row.xlarge-unstack > .columns {
      flex: 1 1 0px; } }

@media screen and (min-width: 75em) {
  .xlarge-collapse > .column, .xlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; } }

@media screen and (min-width: 83.75em) {
  .xxlarge-1 {
    flex: 0 0 10%;
    max-width: 10%; }
  .xxlarge-offset-0 {
    margin-left: 0%; }
  .xxlarge-2 {
    flex: 0 0 20%;
    max-width: 20%; }
  .xxlarge-offset-1 {
    margin-left: 10%; }
  .xxlarge-3 {
    flex: 0 0 30%;
    max-width: 30%; }
  .xxlarge-offset-2 {
    margin-left: 20%; }
  .xxlarge-4 {
    flex: 0 0 40%;
    max-width: 40%; }
  .xxlarge-offset-3 {
    margin-left: 30%; }
  .xxlarge-5 {
    flex: 0 0 50%;
    max-width: 50%; }
  .xxlarge-offset-4 {
    margin-left: 40%; }
  .xxlarge-6 {
    flex: 0 0 60%;
    max-width: 60%; }
  .xxlarge-offset-5 {
    margin-left: 50%; }
  .xxlarge-7 {
    flex: 0 0 70%;
    max-width: 70%; }
  .xxlarge-offset-6 {
    margin-left: 60%; }
  .xxlarge-8 {
    flex: 0 0 80%;
    max-width: 80%; }
  .xxlarge-offset-7 {
    margin-left: 70%; }
  .xxlarge-9 {
    flex: 0 0 90%;
    max-width: 90%; }
  .xxlarge-offset-8 {
    margin-left: 80%; }
  .xxlarge-10 {
    flex: 0 0 100%;
    max-width: 100%; }
  .xxlarge-offset-9 {
    margin-left: 90%; }
  .xxlarge-up-1 {
    flex-wrap: wrap; }
    .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .xxlarge-up-2 {
    flex-wrap: wrap; }
    .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .xxlarge-up-3 {
    flex-wrap: wrap; }
    .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .xxlarge-up-4 {
    flex-wrap: wrap; }
    .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .xxlarge-up-5 {
    flex-wrap: wrap; }
    .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .xxlarge-up-6 {
    flex-wrap: wrap; }
    .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .xxlarge-up-7 {
    flex-wrap: wrap; }
    .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .xxlarge-up-8 {
    flex-wrap: wrap; }
    .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media screen and (min-width: 83.75em) and (min-width: 83.75em) {
  .xxlarge-expand {
    flex: 1 1 0px; } }

.row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
  flex: 0 0 100%; }
  @media screen and (min-width: 83.75em) {
    .row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
      flex: 1 1 0px; } }

@media screen and (min-width: 83.75em) {
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; } }

.shrink {
  flex: 0 0 auto;
  max-width: 100%; }

.column-block {
  margin-bottom: 2.5rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 37.5em) {
    .column-block {
      margin-bottom: 1.25rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-right.vertical.menu > li > a {
  justify-content: flex-end; }

.align-center.vertical.menu > li > a {
  justify-content: center; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 37.5em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 56.25em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

@media screen and (min-width: 75em) {
  .xlarge-order-1 {
    order: 1; }
  .xlarge-order-2 {
    order: 2; }
  .xlarge-order-3 {
    order: 3; }
  .xlarge-order-4 {
    order: 4; }
  .xlarge-order-5 {
    order: 5; }
  .xlarge-order-6 {
    order: 6; } }

@media screen and (min-width: 83.75em) {
  .xxlarge-order-1 {
    order: 1; }
  .xxlarge-order-2 {
    order: 2; }
  .xxlarge-order-3 {
    order: 3; }
  .xxlarge-order-4 {
    order: 4; }
  .xxlarge-order-5 {
    order: 5; }
  .xxlarge-order-6 {
    order: 6; } }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

@media print, screen and (min-width: 37.5em) {
  .medium-flex-container {
    display: flex; }
  .medium-flex-child-auto {
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    flex: 0 1 auto; }
  .medium-flex-dir-row {
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 56.25em) {
  .large-flex-container {
    display: flex; }
  .large-flex-child-auto {
    flex: 1 1 auto; }
  .large-flex-child-grow {
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    flex: 0 1 auto; }
  .large-flex-dir-row {
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media screen and (min-width: 75em) {
  .xlarge-flex-container {
    display: flex; }
  .xlarge-flex-child-auto {
    flex: 1 1 auto; }
  .xlarge-flex-child-grow {
    flex: 1 0 auto; }
  .xlarge-flex-child-shrink {
    flex: 0 1 auto; }
  .xlarge-flex-dir-row {
    flex-direction: row; }
  .xlarge-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .xlarge-flex-dir-column {
    flex-direction: column; }
  .xlarge-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media screen and (min-width: 83.75em) {
  .xxlarge-flex-container {
    display: flex; }
  .xxlarge-flex-child-auto {
    flex: 1 1 auto; }
  .xxlarge-flex-child-grow {
    flex: 1 0 auto; }
  .xxlarge-flex-child-shrink {
    flex: 0 1 auto; }
  .xxlarge-flex-dir-row {
    flex-direction: row; }
  .xxlarge-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .xxlarge-flex-dir-column {
    flex-direction: column; }
  .xxlarge-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

/*
    Main
    ==================================== */
/*
    Common
    ==================================== */
html.navOpen {
  overflow: hidden; }

@media print, screen and (min-width: 56.25em) {
  html.navOpen {
    overflow: visible; } }

h1, .h1 {
  color: #000; }

.h1 {
  margin-bottom: 0; }

h2, .h2 {
  color: #000; }

.h2 {
  margin-bottom: 0; }

h3, .h3 {
  color: #000; }

.h3 {
  margin-bottom: 0; }

h4, .h4 {
  color: #000; }

.h4 {
  margin-bottom: 0; }

h5, .h5 {
  color: #000; }

.h5 {
  margin-bottom: 0; }

h6, .h6 {
  color: #000; }

.h6 {
  margin-bottom: 0; }

h1, .h1, .h1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
  position: relative;
  color: #333;
  line-height: 1;
  text-transform: uppercase; }
  h1:after, .h1:after, .h1:after {
    display: block;
    width: 100%;
    max-width: 80px;
    margin: 20px 0;
    height: 5px;
    border-radius: 2px;
    content: "";
    border: 2px solid #0c7f3f; }
  @media print, screen and (min-width: 56.25em) {
    h1:after, .h1:after, .h1:after {
      margin: 40px 0; } }

a:hover {
  text-decoration: underline; }

.fw-300 {
  font-weight: 300; }

.fw-400 {
  font-weight: 400; }

.fw-700 {
  font-weight: 700; }

.clr-primary {
  color: #344ba0; }

.clr-secondary {
  color: #0c7f3f; }

.clr-white {
  color: #fff; }

.clr-darkGray {
  color: #666; }

.bg-gray {
  background-color: #e8e8e8; }

.bg-darkPattern {
  background: url(../images/bg-dark-pattern.jpg) no-repeat center center;
  background-size: cover; }

.bg-warning {
  background-color: #ecb537; }

.Section {
  padding: 40px 0; }
  @media print, screen and (min-width: 56.25em) {
    .Section {
      padding: 80px 0; } }

.Section--alt {
  color: #fff; }
  .Section--alt h1, .Section--alt .h1, .Section--alt .h1 {
    color: #fff; }

.Section-description {
  margin-bottom: 20px; }
  @media print, screen and (min-width: 56.25em) {
    .Section-description {
      margin-bottom: 40px; } }

.Listing-subHeading {
  margin-bottom: 25px; }

.Bullet {
  position: relative;
  list-style: none; }
  .Bullet:before {
    width: 9px;
    height: 10px;
    position: absolute;
    top: 8px;
    left: -20px;
    background: url(../images/bullet.png) no-repeat center center;
    content: ""; }

.Frame {
  width: 100%;
  height: 0;
  padding-top: 50%;
  position: relative; }
  @media print, screen and (min-width: 56.25em) {
    .Frame {
      padding-top: 33.33333%; } }

.Frame-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 0; }

/*
    Components
    ==================================== */
.Form-group {
  padding: 8px 0; }

.Form-label {
  display: block;
  padding: 9px 0;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.71429; }

.Form-input {
  display: block;
  width: 100%;
  padding: 12px 10px;
  background-color: #fff;
  border: 1px solid #344ba0;
  border-radius: 2px;
  box-shadow: none; }
  @media print, screen and (min-width: 37.5em) {
    .Form-input {
      font-size: 14px; } }

.Form-input--textarea {
  resize: none; }

.Form-warning {
  margin: 0;
  padding: 5px;
  font-size: 14px; }

.Button {
  display: block;
  width: 100%;
  padding: 12px 10px;
  background-color: #344ba0;
  color: #fff;
  cursor: pointer;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 18px;
  font-weight: 900;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  transition: background-color, 0.2s ease-in-out 0s; }
  .Button:hover, .Button:focus {
    background-color: #455fc2;
    color: #fff;
    text-decoration: none; }

.Button--large {
  padding-top: 17px;
  padding-bottom: 17px;
  font-size: 22px; }

.Contact {
  font-size: 13px;
  line-height: 1.84615; }

.Contact-list {
  margin: 0; }

.Contact-group {
  margin-right: -1.25rem;
  margin-left: -1.25rem;
  display: flex;
  flex-flow: row wrap;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px; }
  @media print, screen and (min-width: 37.5em) {
    .Contact-group {
      margin-right: -0.625rem;
      margin-left: -0.625rem; } }
  @media print, screen and (min-width: 56.25em) {
    .Contact-group {
      margin-right: -0.625rem;
      margin-left: -0.625rem; } }
  @media screen and (min-width: 75em) {
    .Contact-group {
      margin-right: -0.625rem;
      margin-left: -0.625rem; } }
  @media screen and (min-width: 83.75em) {
    .Contact-group {
      margin-right: -0.625rem;
      margin-left: -0.625rem; } }
  .Contact-group:first-child {
    margin-top: 0; }
  @media print, screen and (min-width: 37.5em) {
    .Contact-group {
      flex-direction: row;
      margin-top: 25px; } }
  @media print, screen and (min-width: 56.25em) {
    .Contact-group:first-child {
      margin-top: 20px; } }

.Contact-label {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  margin: 10px 0 0;
  color: #666; }
  @media print, screen and (min-width: 37.5em) {
    .Contact-label {
      flex: 0 0 30%;
      max-width: 30%;
      padding-right: 0;
      padding-left: 0;
      margin-top: 0;
      padding-right: 10px; } }
  @media print, screen and (min-width: 56.25em) {
    .Contact-label {
      flex: 0 0 33%;
      max-width: 33%;
      padding-right: 0;
      padding-left: 0;
      padding-right: 10px; } }

.Contact-description {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem; }
  @media print, screen and (min-width: 37.5em) {
    .Contact-description {
      flex: 0 0 70%;
      max-width: 70%;
      padding-right: 0;
      padding-left: 0;
      padding-left: 10px; }
      .Contact-description + .Contact-description {
        margin-left: 30%; } }
  @media print, screen and (min-width: 56.25em) {
    .Contact-description {
      flex: 0 0 66%;
      max-width: 66%;
      padding-right: 0;
      padding-left: 0;
      padding-left: 10px; }
      .Contact-description + .Contact-description {
        margin-left: 33%; } }

.Member-image {
  width: 100%;
  margin: 20px 0; }

.Member-title {
  display: block;
  font-size: 14px;
  line-height: 1.42857; }

.Member-info {
  margin-top: 15px;
  font-size: 13px;
  line-height: 1.53846; }

.References-image {
  margin: 10px auto; }

.ReferenceList {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  list-style: none; }

.ReferenceList-item {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  margin: 25px 0; }
  @media print, screen and (min-width: 37.5em) {
    .ReferenceList-item {
      flex: 0 0 50%;
      max-width: 50%;
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }

.ReferenceList-subList {
  margin-left: 30px; }

.ReferenceList-title,
.ReferenceList-address {
  padding: 0 30px; }

.ReferenceList-address {
  font-size: 14px; }

.ReferenceList-subItem {
  font-size: 14px;
  line-height: 1.85714; }

.ServiceList {
  margin-right: -1.25rem;
  margin-left: -1.25rem;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 0;
  justify-content: center;
  list-style: none; }
  @media print, screen and (min-width: 37.5em) {
    .ServiceList {
      margin-right: -0.625rem;
      margin-left: -0.625rem; } }
  @media print, screen and (min-width: 56.25em) {
    .ServiceList {
      margin-right: -0.625rem;
      margin-left: -0.625rem; } }
  @media screen and (min-width: 75em) {
    .ServiceList {
      margin-right: -0.625rem;
      margin-left: -0.625rem; } }
  @media screen and (min-width: 83.75em) {
    .ServiceList {
      margin-right: -0.625rem;
      margin-left: -0.625rem; } }

.ServiceList-item {
  flex: 0 0 80%;
  max-width: 80%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  margin: 25px 0 0 3.75rem; }
  @media print, screen and (min-width: 37.5em) {
    .ServiceList-item {
      flex: 0 0 70%;
      max-width: 70%;
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      margin-left: 7.5rem; } }
  @media print, screen and (min-width: 56.25em) {
    .ServiceList-item {
      flex: 0 0 33%;
      max-width: 33%;
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  @media screen and (min-width: 75em) {
    .ServiceList-item {
      flex: 0 0 20%;
      max-width: 20%;
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }

@media screen and (min-width: 75em) {
  .ServiceList-item--designing {
    flex: 0 0 30%;
    max-width: 30%;
    padding-right: 0.625rem;
    padding-left: 0.625rem; } }

.ServiceList-title {
  margin-bottom: 15px;
  position: relative; }
  .ServiceList-title:before {
    width: 40px;
    height: 40px;
    position: absolute;
    top: -8px;
    left: auto;
    right: calc(100% + 20px);
    background: no-repeat center center;
    background-size: contain;
    content: ""; }
  .ServiceList-item--designing .ServiceList-title:before {
    background-image: url(../images/icon-tools.png); }
  .ServiceList-item--production .ServiceList-title:before {
    background-image: url(../images/icon-plan.png); }
  .ServiceList-item--supervision .ServiceList-title:before {
    background-image: url(../images/icon-worker.png); }
  @media screen and (min-width: 75em) {
    .ServiceList-title:before {
      top: 0; }
    .ServiceList-item--designing .ServiceList-title:before {
      width: 70px;
      height: 71px; }
    .ServiceList-item--production .ServiceList-title:before {
      width: 71px;
      height: 72px; }
    .ServiceList-item--supervision .ServiceList-title:before {
      width: 59px;
      height: 72px; } }

.ServiceList-subList {
  margin-left: 0; }

.ServiceList-subItem:before {
  left: -30px; }

.Hero {
  background: url(../images/bg-hero.jpg) no-repeat center center;
  background-size: cover; }

.Hero-cover {
  padding: 40px 0;
  background-color: rgba(52, 75, 160, 0.8); }
  @media print, screen and (min-width: 56.25em) {
    .Hero-cover {
      margin: 0 -10px;
      padding: 80px 0; } }

.Hero-tagline {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  margin: auto;
  font-style: italic; }
  @media print, screen and (min-width: 56.25em) {
    .Hero-tagline {
      flex: 0 0 80%;
      max-width: 80%;
      padding-right: 0;
      padding-left: 0; } }
  @media screen and (min-width: 75em) {
    .Hero-tagline {
      flex: 0 0 66%;
      max-width: 66%;
      padding-right: 0;
      padding-left: 0; } }

.Header {
  position: relative;
  z-index: 10; }
  .Header:after {
    position: absolute;
    left: 0;
    right: 0;
    top: auto;
    bottom: -2px;
    z-index: 1;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.05);
    content: ""; }

.Header-container {
  background-color: #fff; }
  .isSticky .Header-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0; }

.Header-content {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap; }

/* burger */
.Header-burger {
  flex-basis: 33%; }

.Burger {
  display: flex;
  height: 100%;
  padding: 0 10px;
  align-items: center; }

.Burger-slice {
  display: block;
  width: 30px;
  height: 3px;
  position: relative;
  background-color: #344ba0;
  transition: background-color 0.2s ease-out 0s; }
  .Burger-slice:before, .Burger-slice:after {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    background-color: #344ba0;
    content: "";
    transition: transform 0.2s ease-out 0s; }
  .Burger-slice:before {
    top: -7px; }
  .Burger-slice:after {
    top: 7px; }
  .navOpen .Burger-slice {
    background-color: transparent; }
    .navOpen .Burger-slice:before, .navOpen .Burger-slice:after {
      top: 0; }
    .navOpen .Burger-slice:before {
      transform: rotate(-45deg); }
    .navOpen .Burger-slice:after {
      transform: rotate(45deg); }

/* logo */
.Header-logo {
  display: block;
  height: 40px;
  flex-basis: 33%;
  text-transform: none; }
  .Header-logo:after {
    display: none;
    content: normal; }
  @media print, screen and (min-width: 56.25em) {
    .Header-logo {
      height: auto;
      flex-basis: 10%;
      align-self: center; } }
  @media screen and (min-width: 75em) {
    .Header-logo {
      flex-basis: 20%;
      align-self: stretch;
      position: relative; } }

.Logo {
  display: inline-block;
  height: 100%;
  position: relative;
  overflow: hidden; }
  @media print, screen and (min-width: 56.25em) {
    .Logo {
      overflow: visible; } }
  @media screen and (min-width: 75em) {
    .Logo {
      width: 100%;
      height: auto;
      padding: 20px 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      background-color: #fff; }
      .Logo:after {
        position: absolute;
        left: 0;
        top: auto;
        right: 0;
        bottom: -2px;
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.05);
        content: ""; } }

.Logo-image {
  height: calc(100% + 10px); }
  @media print, screen and (min-width: 56.25em) {
    .Logo-image {
      height: auto; } }

/* navigation */
.Header-navigation {
  position: relative;
  z-index: -1; }
  @media print, screen and (min-width: 56.25em) {
    .Header-navigation {
      margin: auto;
      z-index: auto; } }

.NavigationList {
  max-width: 460px;
  width: 100%;
  height: 100%;
  margin: 0;
  padding-top: 60px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5;
  background-color: rgba(255, 255, 255, 0.99);
  list-style: none;
  transform: translateX(-100%);
  transition: transform 0.2s ease-out 0s; }
  .navOpen .NavigationList {
    transform: translateX(0); }
  @media print, screen and (min-width: 56.25em) {
    .NavigationList {
      display: flex;
      max-width: none;
      padding-top: 0;
      position: static;
      left: auto;
      top: auto;
      background-color: transparent;
      transform: translateX(0); } }

.NavigationList-link {
  display: block;
  padding: 10px 40px;
  position: relative;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 24px;
  font-weight: 900;
  line-height: 1;
  text-transform: uppercase; }
  .NavigationList-link:hover {
    color: #0c7f3f;
    text-decoration: none; }
  .NavigationList-link:before {
    width: 9px;
    height: 10px;
    position: absolute;
    top: 23px;
    left: 20px;
    background: url(../images/bullet-alt.png) no-repeat center center;
    content: ""; }
  @media print, screen and (min-width: 37.5em) {
    .NavigationList-link {
      padding: 20px 80px; }
      .NavigationList-link:before {
        top: 33px;
        left: 60px; } }
  @media print, screen and (min-width: 56.25em) {
    .NavigationList-link {
      padding: 46px 20px;
      font-size: 18px; }
      .NavigationList-link:before {
        top: 50px;
        left: 5px; } }
  @media screen and (min-width: 75em) {
    .NavigationList-link {
      padding: 46px 35px; }
      .NavigationList-link:before {
        left: 15px; } }

/* language switcher */
.Header-languageSwitch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-basis: 33%; }
  @media print, screen and (min-width: 56.25em) {
    .Header-languageSwitch {
      flex-basis: 10%; } }

.LanguageSwitch {
  display: flex;
  margin: 0 5px;
  list-style: none; }

.LanguageSwitch-item {
  display: flex;
  align-items: center;
  font-size: 13px; }
  .LanguageSwitch-item:before {
    color: #ccc;
    content: "/"; }
  .LanguageSwitch-item:first-child:before {
    content: ""; }

.LanguageSwitch-link {
  display: inline-block;
  padding: 0 5px;
  font-family: "Roboto", Arial, sans-serif; }
  .LanguageSwitch-link.current {
    color: #000;
    font-weight: 900; }

.LanguageSwitch-abbreviation[title] {
  border: none;
  cursor: pointer;
  text-decoration: none; }
