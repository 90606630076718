.Contact {
    font-size: 13px;
    line-height: (24/13);
}
.Contact-list {
    margin: 0;
}
.Contact-group {
    @include flex-grid-row('nest', 10, 2);

    flex-direction: column;
    justify-content: center;
    margin-top: 20px;

    &:first-child {
        margin-top: 0;
    }

    @include breakpoint(medium) {
        flex-direction: row;
        margin-top: 25px;
    }
    @include breakpoint(large) {
        &:first-child {
            margin-top: 20px;
        }
    }
}
.Contact-label {
    @include flex-grid-column(10, 40);
    margin: 10px 0 0;

    color: $dark-gray;

    @include breakpoint(medium) {
        @include flex-grid-column(3, 0);
        margin-top: 0;
        padding-right: 10px;
    }
    @include breakpoint(large) {
        @include flex-grid-column(3.3, 0);
        padding-right: 10px;
    }
}
.Contact-description {
    @include flex-grid-column(10, 40);

    @include breakpoint(medium) {
        @include flex-grid-column(7, 0);
        padding-left: 10px;

        & + & {
            margin-left: 30%;
        }
    }
    @include breakpoint(large) {
        @include flex-grid-column(6.6, 0);
        padding-left: 10px;

        & + & {
            margin-left: 33%;
        }
    }
}

