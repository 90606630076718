.Header {
    position: relative;
    z-index: 10;

    &:after {
        position: absolute;
        left: 0;
        right: 0;
        top: auto;
        bottom: -2px;
        z-index: 1;

        box-shadow: 0 0 1px 1px rgba($black, 0.05);
        content: "";
    }
}
.Header-container {
    background-color: $white;

    .isSticky & {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }
}
.Header-content {
    @include flex-grid-row();
}
/* burger */
.Header-burger {
    flex-basis: 33%;
}
.Burger {
    display: flex;
    height: 100%;
    padding: 0 10px;
    align-items: center;
}
.Burger-slice {
    display: block;
    width: 30px;
    height: 3px;

    position: relative;

    background-color: $primary-color;
    transition: background-color 0.2s ease-out 0s;

    &:before,
    &:after {
        width: 100%;
        height: 100%;

        position: absolute;
        left: 0;

        background-color: $primary-color;
        content: "";
        transition: transform 0.2s ease-out 0s;
    }
    &:before {
        top: -7px;
    }
    &:after {
        top: 7px;
    }

    .navOpen & {
        background-color: transparent;

        &:before,
        &:after {
            top: 0;
        }
        &:before {
            transform: rotate(-45deg);
        }
        &:after {
            transform: rotate(45deg);
        }
    }
}
/* logo */
.Header-logo {
    display: block;
    height: 40px;
    flex-basis: 33%;

    text-transform: none;

    &:after {
        display: none;
        content: normal;
    }

    @include breakpoint(large) {
        height: auto;
        flex-basis: 10%;
        align-self: center;
    }
    @include breakpoint(xlarge) {
        flex-basis: 20%;
        align-self: stretch;

        position: relative;
    }
}
.Logo {
    display: inline-block;
    height: 100%;

    position: relative;

    overflow: hidden;

    @include breakpoint(large) {
        overflow: visible;
    }
    @include breakpoint(xlarge) {
        width: 100%;
        height: auto;
        padding: 20px 0;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;

        background-color: $white;

        &:after {
            position: absolute;
            left: 0;
            top: auto;
            right: 0;
            bottom: -2px;

            box-shadow: 0 0 1px 1px rgba($black, 0.05);
            content: "";
        }
    }
}
.Logo-image {
    height: calc(100% + 10px);

    @include breakpoint(large) {
        height: auto;
    }
}
/* navigation */
.Header-navigation {
    position: relative;
    z-index: -1;

    @include breakpoint(large) {
        margin: auto;

        z-index: auto;
    }
}
.NavigationList {
    max-width: 460px;
    width: 100%;
    height: 100%;
    margin: 0;
    padding-top: 60px;

    position: fixed;
    left: 0;
    top: 0;
    z-index: 5;

    background-color: rgba($white, 0.99);
    list-style: none;
    transform: translateX(-100%);
    transition: transform 0.2s ease-out 0s;

    .navOpen & {
        transform: translateX(0);
    }
    @include breakpoint(large) {
        display: flex;
        max-width: none;
        padding-top: 0;

        position: static;
        left: auto;
        top: auto;

        background-color: transparent;
        transform: translateX(0);
    }
}
.NavigationList-link {
    display: block;
    padding: 10px 40px;

    position: relative;

    font-family: $header-font-family;
    font-size: 24px;
    font-weight: 900;
    line-height: 1;
    text-transform: uppercase;

    &:hover {
        color: $secondary-color;
        text-decoration: none;
    }
    &:before {
        width: 9px;
        height: 10px;

        position: absolute;
        top: 23px;
        left: 20px;

        background: url(../images/bullet-alt.png) no-repeat center center;
        content: "";
    }

    @include breakpoint(medium) {
        padding: 20px 80px;

        &:before {
            top: 33px;
            left: 60px;
        }
    }
    @include breakpoint(large) {
        padding: 46px 20px;

        font-size: 18px;

        &:before {
            top: 50px;
            left: 5px;
        }
    }
    @include breakpoint(xlarge) {
        padding: 46px 35px;

        &:before {
            left: 15px;
        }
    }
}
/* language switcher */
.Header-languageSwitch {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-basis: 33%;

    @include breakpoint(large) {
        flex-basis: 10%;
    }
}
.LanguageSwitch {
    display: flex;
    margin: 0 5px;

    list-style: none;
}
.LanguageSwitch-item {
    display: flex;
    align-items: center;

    font-size: 13px;

    &:before {
        color: $medium-gray;
        content: "/";
    }
    &:first-child {
        &:before {
            content: "";
        }
    }
}
.LanguageSwitch-link {
    display: inline-block;
    padding: 0 5px;

    font-family: $header-font-family;

    &.current {
        color: $black;
        font-weight: 900;
    }
}
.LanguageSwitch-abbreviation {
    &[title] {
        border: none;
        cursor: pointer;
        text-decoration: none;
    }
}
