.Hero {
    background: url(../images/bg-hero.jpg) no-repeat center center;
    background-size: cover;
}
.Hero-cover {
    //@include flex-grid-row();

    padding: 40px 0;

    background-color: rgba($primary-color, 0.8);

    @include breakpoint(large) {
        margin: 0 -10px;
        padding: 80px 0;
    }
}
.Hero-tagline {
    @include flex-grid-column(10, 40);

    margin: auto;

    font-style: italic;

    @include breakpoint(large) {
        @include flex-grid-column(8, 0);
    }
    @include breakpoint(xlarge) {
        @include flex-grid-column(6.6, 0);
    }
}