.Form-group {
    padding: 8px 0;
}
.Form-label {
    display: block;
    padding: 9px 0;

    color: $dark;
    cursor: pointer;
    font-size: 14px;
    line-height: (24/14);
}
.Form-input {
    display: block;
    width: 100%;
    padding: 12px 10px;

    background-color: $white;
    border: 1px solid $primary-color;
    border-radius: 2px;
    box-shadow: none;

    @include breakpoint(medium) {
        font-size: 14px;
    }
}
.Form-input--textarea {
    resize: none;
}
.Form-warning {
    margin: 0;
    padding: 5px;

    font-size: 14px;
}
.Button {
    display: block;
    width: 100%;
    padding: 12px 10px;

    background-color: $primary-color;
    color: $white;
    cursor: pointer;
    font-family: $alt-font-family;
    font-size: 18px;
    font-weight: 900;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    transition: background-color, 0.2s ease-in-out 0s;

    &:hover,
    &:focus {
        background-color: lighten($primary-color, 10);
        color: $white;
        text-decoration: none;
    }
}
.Button--large {
    padding-top: 17px;
    padding-bottom: 17px;

    font-size: 22px;
}