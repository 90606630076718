html {
    &.navOpen {
        overflow: hidden;
    }

    @include breakpoint(large) {
        &.navOpen {
            overflow: visible;
        }
    }
}
@for $i from 1 through 6 {
    h#{$i} {
        color: $black;
    }
    .h#{$i} {
        @extend h#{$i};
        margin-bottom: 0;
    }
}
h1, .h1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;

    position: relative;

    color: $dark;
    line-height: 1;
    text-transform: uppercase;

    &:after {
        display: block;
        width: 100%;
        max-width: 80px;
        margin: 20px 0;
        height: 5px;

        //position: absolute;

        border-radius: 2px;
        content: "";
        border: 2px solid $secondary-color;
    }

    @include breakpoint(large) {
        &:after {
            margin: 40px 0;
        }
    }
}
a {
    &:hover {
        text-decoration: underline;
    }
}
.fw-300 {
    font-weight: 300;
}
.fw-400 {
    font-weight: 400;
}
.fw-700 {
    font-weight: 700;
}
.clr-primary {
    color: $primary-color;
}
.clr-secondary {
    color: $secondary-color;
}
.clr-white {
    color: $white;
}
.clr-darkGray {
    color: $dark-gray;
}
.bg-gray {
    background-color: $light-gray;
}
.bg-darkPattern {
    background: url(../images/bg-dark-pattern.jpg) no-repeat center center;
    background-size: cover;
}
.bg-warning {
    background-color: $warning-color;
}

.Section {
    padding: 40px 0;

    @include breakpoint(large) {
        padding: 80px 0;
    }
}
.Section--alt {
    color: $white;

    h1, .h1 {
        color: $white;
    }
}
.Section-description {
    margin-bottom: 20px;

    @include breakpoint(large) {
        margin-bottom: 40px;
    }
}
.Listing-subHeading {
    margin-bottom: 25px;
}
.Bullet {
    position: relative;

    list-style: none;

    &:before {
        width: 9px;
        height: 10px;

        position: absolute;
        top: 8px;
        left: -20px;

        background: url(../images/bullet.png) no-repeat center center;
        content: "";
    }
}
.Frame {
    width: 100%;
    height: 0;
    padding-top: percentage(1/2);

    position: relative;

    @include breakpoint(large) {
        padding-top: percentage(1/3);
    }
}
.Frame-content {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    border: 0;
}